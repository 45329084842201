<template>
  <div class="ma-10" v-if="!displayQr">
    <v-card outlined class="mb-5">
      <!-- Expansion -->
      <v-expansion-panels flat>
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header>
            <span class="headline">検索条件</span>
          </v-expansion-panel-header>

          <!-- Body -->
          <v-expansion-panel-content>
            <v-autocomplete
              v-model="selectFilterAffiliation"
              :items="filterAffiliations"
              label="所属"
              multiple
              clearable
              chips
              dense
              item-text="name"
              item-value="code"
            >
            </v-autocomplete>
            <!-- <v-autocomplete
              v-model="selectFilterAffiliationGroup"
              :items="filterAffiliationGroups"
              label="所属グループ"
              multiple
              clearable
              chips
              dense
              item-text="name"
              item-value="code"
            >
            </v-autocomplete> -->
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Actions -->
      <v-card-actions class="ma-2">
        <v-spacer />
        <Btn color="info" icon="mdi-magnify" @click="select">検索</Btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-5">
      <v-card-actions class="ma-2">
        <v-spacer />
        <Btn
          icon="mdi-qrcode"
          color="primary"
          text
          outlined
          :disabled="!isSelected"
          @click="display"
        >
          QRコード表示
        </Btn>
      </v-card-actions>
    </v-card>
    <!-- Table -->
    <v-card class="mt-5">
      <v-card-title>
        <span class="text-h4">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="簡易検索"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        show-select
        :item-key="itemKey"
        multi-sort
      >
        <template v-slot:item.midnight="{ item }"
          ><v-checkbox v-model="item.midnight" readonly />
        </template>

        <!-- <template v-slot:item.actions="{ item }">
          <v-icon small @click="actions('details', item)" color="info">
            mdi-details
          </v-icon>
        </template> -->
      </v-data-table>
    </v-card>
  </div>
  <div v-else>
    <v-card-actions class="no-print">
      <v-spacer></v-spacer>
      <Btn
        icon="mdi-keyboard-return"
        color="primary"
        text
        outlined
        :disabled="!isSelected"
        @click="nondisplay"
      >
        選択画面に戻る
      </Btn>
      <Btn color="primary" icon="mdi-printer" @click="print">印刷</Btn>
    </v-card-actions>
    <div class="qr-base">
      <template v-for="(page, pageIndex) in selectTargetSlice">
        <div class="qr-page" :key="pageIndex">
          <template v-for="(qr, qrIndex) in page">
            <div class="qr-template" :key="qrIndex">
              <v-card-text>
                <div class="qrBlock">
                  <div class="qr-card">
                    <QrCode
                      :value="qr.qrCode"
                      :size="130"
                      :showText="false"
                      class="qr-code-style"
                    />
                  </div>
                  <div class="qr-word-area">
                    <div class="qr-text">
                      <span>
                        {{ "【施設】" }}<br /><br />
                        {{ qr.name1 }}<br />
                      </span>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import md5 from "md5";

export default {
  name: "QrShozoku",
  mixins: [Common, ShowDialogs, Api],
  components: {
    QrCode: () => import("@/components/QrCode.vue")
  },
  props: {},
  data() {
    return {
      selected: [],
      search: "",
      open: true,
      title: "テーブル名",
      itemKey: "name",
      headers: [],
      items: [],
      filterAffiliations: [],
      filterAffiliationGroups: [],
      selectFilterAffiliation: [],
      selectFilterAffiliationGroup: [],
      filterUserName: "",
      displayQr: false,
      selectTarget: []
    };
  },
  computed: {
    isSelected() {
      return !!this.selected && this.selected.length > 0;
    },
    selectTargetSlice() {
      const pageNumber = 10;
      const array = this.selectTarget;
      const length = Math.ceil(array.length / pageNumber);
      return new Array(length)
        .fill()
        .map((_, i) => array.slice(i * pageNumber, (i + 1) * pageNumber));
    }
  },
  methods: {
    display() {
      this.displayQr = true;
      this.selectTarget = [];
      this.selected.forEach(s => {
        const target = s;
        target.qrCode = s.code + "@" + md5(s.code);
        this.selectTarget.push(target);
      });
      console.log("selectTarget", this.selectTarget);
    },
    nondisplay() {
      this.displayQr = false;
    },
    print() {
      window.print();
    },
    getBreak(index) {
      if (index == 0) return "qr-template margin-f-first";
      if (index % 4 == 0) return "qr-template break-f-print";
      return "qr-template";
    },
    async actions(prosess, args) {
      console.log(prosess, args);
    },
    async clickDelete() {
      if (await this.deleteConfirm())
        this.items = this.items.filter(e => !this.selected.includes(e));
    },
    async getdata(path) {
      this.$loading();
      try {
        return await this.$get(path);
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async select() {
      console.log("select");
      const ids = [];
      this.selectFilterAffiliation.forEach(s => {
        ids.push(s);
      });
      this.selectFilterAffiliationGroup.forEach(s => {
        ids.push(s);
      });
      const param = ids.join(",");

      const encode = encodeURI(param);
      console.log("encode", encode);
      this.$loading();
      try {
        const data = await this.$get(this.Paths.shozokuQr, "query=" + encode);
        await this.setdata(data);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async update() {
      console.log("==update");
      this.$loading();
      try {
        this.filterAffiliations = await this.$get(this.Paths.shozoku);
        this.filterAffiliationGroups = await this.$get(
          this.Paths.shozokuDetailPullGroup
        );
        const data = await this.$get(this.Paths.shozokuQr);
        await this.setdata(data);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async setdata(data) {
      console.log("setdata");
      this.selected = [];
      this.headers = data.headers;
      this.items = data.items;
      this.title = data.name;
      this.itemKey = data.key;
    }
  },
  created() {
    console.log("==created");
    this.update();
  },
  mounted() {
    console.log("==mounted");
  },
  beforeUpdate() {
    console.log("==beforeUpdate");
  },
  updated() {
    console.log("==updated");
  }
};
</script>
<style>
.qr-display {
  display: flex;
}
.qrBlock {
  height: 45mm;
  width: 80mm;
  background: ghostwhite;
  border: 1px solid gray;
}
/*.qr-base {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}*/
.qr-template {
  width: 50%;
  min-width: 120mm;
  text-align: -webkit-center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qr-card {
  float: left;
  height: 90%;
  width: 47%;
}
.qr-code-style {
  margin: 5mm 3px;
  text-align: center;
}
.qr-word-area {
  float: right;
  height: 100%;
  width: 47%;
  overflow: hidden;
}
.qr-text {
  margin: 40px 2px;
  font-size: 16px;
  text-align: initial;
}
.qr-page {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
}
@page {
  size: A4 portrait;
  margin: 0mm;
}
@media print {
  .no-print {
    display: none;
  }
  .margin-f-first {
    margin: 0%;
  }
  .break-f-print {
    break-before: page;
  }
  /* ナビゲートが印刷範囲に含まれているので非表示 */
  nav,
  nav * {
    display: none !important;
  }
  /* 余白をリセット */
  :not(.qr-base, .qr-base *) {
    margin: 0 !important;
    padding: 0 !important;
  }
  /* vueのデフォルトの余白をリセット */
  .v-card__text {
    padding: 0 !important;
  }
  /* 要素のサイズ調整 */
  .qr-template {
    width: 50% !important;
    height: 20% !important;
    min-width: 0 !important;
  }
  .qr-page {
    width: 210mm;
    height: 296.5mm;
    break-after: page;
    box-sizing: border-box;
    padding: 1.5% 0;
  }
  .qr-page:last-child {
    break-after: auto;
  }
}
</style>
